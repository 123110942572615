import React from 'react'
import PropTypes from 'prop-types'
import css from '@styled-system/css'
import useSiteMetadata from 'hooks/useSiteMetadata'
import FormState from '../containers/FormState'
import Header from './Header'
import LoginForm from './LoginForm'
import SignUpForm from './SignUpForm'
import PasswordResetForm from './PasswordResetForm'
import Nav from './Nav'
import ErrorMessage from './ErrorMessage'

const Form = ({ onLoginSuccess, onSignUpSuccess, onResetSuccess }) => {
  const siteMetadata = useSiteMetadata()
  const { formType, errorMessage } = FormState.useContainer()

  return (
    <div
      css={css({
        width: '100%',
        maxWidth: '28rem',
        margin: '0 auto',
        boxShadow: '0 0 1.5rem 0 rgba(0,0,0,0.25)',
      })}
    >
      <Header css={css({ textAlign: 'center' })}>
        <h1
          css={css({
            my: 1,
            color: 'dark',
          })}
        >
          {siteMetadata.title}
        </h1>
      </Header>
      <Nav />
      {errorMessage && <ErrorMessage message={errorMessage} />}
      {formType === 'login' && <LoginForm onSuccess={onLoginSuccess} />}
      {formType === 'signup' && <SignUpForm onSuccess={onSignUpSuccess} />}
      {formType === 'passwordReset' && (
        <PasswordResetForm onSuccess={onResetSuccess} />
      )}
    </div>
  )
}

Form.propTypes = {
  onLoginSuccess: PropTypes.func,
  onSignUpSuccess: PropTypes.func,
  onResetSuccess: PropTypes.func,
}

export default Form
