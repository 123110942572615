import React, { useState } from 'react'
import PropTypes from 'prop-types'
import css from '@styled-system/css'
import FormState from '../containers/FormState'
import { auth } from '../../firebase'
import Form from './FormBase'
import Input from './Input'
import { Button } from 'ui-components'

const PasswordResetForm = ({ onSuccess = () => {}, ...restProps }) => {
  const { setErrorMessage } = FormState.useContainer()
  const [email, setEmail] = useState('')

  return (
    <Form
      {...restProps}
      onSubmit={async event => {
        event.preventDefault()
        try {
          await auth.sendPasswordResetEmail(email)
          onSuccess()
        } catch (error) {
          setErrorMessage(error.message)
        }
      }}
    >
      <Input
        label="Email"
        type="text"
        placeholder="email@example.com"
        value={email}
        css={css({ mb: 3 })}
        onChange={event => {
          setEmail(event.target.value)
        }}
      />
      <Button type="submit" css={{ width: '100%' }}>
        Send email
      </Button>
    </Form>
  )
}

PasswordResetForm.propTypes = {
  onSuccess: PropTypes.func,
}

export default PasswordResetForm
