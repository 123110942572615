import React, { useState } from 'react'
import css from '@styled-system/css'
import PropTypes from 'prop-types'
import FormState from '../containers/FormState'
import { auth } from '../../firebase'
import Form from './FormBase'
import Input from './Input'
import { Button } from 'ui-components'

const SignUpForm = ({ onSuccess = () => {}, ...restProps }) => {
  const { setErrorMessage } = FormState.useContainer()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  return (
    <Form
      {...restProps}
      onSubmit={async event => {
        event.preventDefault()
        try {
          const { user } = await auth.createUserWithEmailAndPassword(
            email,
            password,
          )
          if (user) {
            await user.updateProfile({ displayName: name })
            await user.sendEmailVerification()
          }
          onSuccess(user)
        } catch (error) {
          setErrorMessage(error.message)
        }
      }}
    >
      <Input
        label="Name"
        type="text"
        placeholder="John Doe"
        value={name}
        onChange={event => {
          setName(event.target.value)
        }}
      />
      <Input
        label="Email"
        type="text"
        placeholder="email@example.com"
        value={email}
        onChange={event => {
          setEmail(event.target.value)
        }}
      />
      <Input
        label="Password"
        type="password"
        value={password}
        css={css({ mb: 3 })}
        onChange={event => {
          setPassword(event.target.value)
        }}
      />
      <Button type="submit" css={{ width: '100%' }}>
        Sign up
      </Button>
    </Form>
  )
}

SignUpForm.propTypes = {
  onSuccess: PropTypes.func,
}

export default SignUpForm
