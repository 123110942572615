import { useState } from 'react'
import { createContainer } from 'unstated-next'

// FormType: "login" | "signup" | "passwordReset"
const initialState = {
  formType: 'login',
  errorMessage: '',
}

function useForm(state = initialState) {
  const [formType, setFormType] = useState(state.formType)
  const [errorMessage, setErrorMessage] = useState(state.errorMessage)

  return {
    formType,
    setFormType: type => {
      setErrorMessage('')
      setFormType(type)
    },
    errorMessage,
    setErrorMessage,
  }
}

const FormState = createContainer(useForm)

export default FormState
