/** Global Web App Style Guides */

// Default Breakpoints: @media screen and (min-width: xxx)
const DEFAULT_BREAK_POINTS = ['576px', '768px', '1024px', '1200px']
// Breakpoints Alias: sm/md/lg/xl
DEFAULT_BREAK_POINTS.sm = DEFAULT_BREAK_POINTS[0]
DEFAULT_BREAK_POINTS.md = DEFAULT_BREAK_POINTS[1]
DEFAULT_BREAK_POINTS.tablet = DEFAULT_BREAK_POINTS[1] // alias of tablet
DEFAULT_BREAK_POINTS.lg = DEFAULT_BREAK_POINTS[2]
DEFAULT_BREAK_POINTS.desktop = DEFAULT_BREAK_POINTS[2] // alias of desktop
DEFAULT_BREAK_POINTS.xl = DEFAULT_BREAK_POINTS[3]

const mediaQueries = Object.entries(DEFAULT_BREAK_POINTS).reduce(
  (mediaQueries, [alias, breakpoint]) => {
    mediaQueries[alias] = `@media screen and (min-width: ${breakpoint})`
    return mediaQueries
  },
  {},
)

// Default FontSizes
const DEFAULT_FONT_SIZES = [11, 12, 13, 14, 15, 16, 18, 20, 24, 28, 32, 48, 64]
// Alias
DEFAULT_FONT_SIZES.small = DEFAULT_FONT_SIZES[0] // 11
DEFAULT_FONT_SIZES.body = DEFAULT_FONT_SIZES[1] // 12
DEFAULT_FONT_SIZES.introduction = DEFAULT_FONT_SIZES[2] // 13
DEFAULT_FONT_SIZES.intro = DEFAULT_FONT_SIZES[2] // alias of introduction
DEFAULT_FONT_SIZES.h6 = DEFAULT_FONT_SIZES[1] // 12
DEFAULT_FONT_SIZES.h5 = DEFAULT_FONT_SIZES[1] // 12
DEFAULT_FONT_SIZES.h4 = DEFAULT_FONT_SIZES[3] // 14
DEFAULT_FONT_SIZES.h3 = DEFAULT_FONT_SIZES[4] //15
DEFAULT_FONT_SIZES.h2 = DEFAULT_FONT_SIZES[6] // 18
DEFAULT_FONT_SIZES.h1 = DEFAULT_FONT_SIZES[8] // 24

// Default Space for Margin and Padding
const DEFAULT_SPACES = [0, 4, 8, 16, 20, 24, 32, 40, 48, 56, 64, 128, 256, 512]

// Default z-index. DEFAULT_ZINDICES[10] is for dev, don't use it.
const DEFAULT_ZINDICES = [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]
DEFAULT_ZINDICES.modal = DEFAULT_ZINDICES[9]
DEFAULT_ZINDICES.topmost = DEFAULT_ZINDICES[9]
DEFAULT_ZINDICES.header = DEFAULT_ZINDICES[8]
DEFAULT_ZINDICES.bottommost = -900
DEFAULT_ZINDICES.l1 = DEFAULT_ZINDICES[1]
DEFAULT_ZINDICES.l2 = DEFAULT_ZINDICES[2]
DEFAULT_ZINDICES.l3 = DEFAULT_ZINDICES[3]
DEFAULT_ZINDICES.l4 = DEFAULT_ZINDICES[4]
DEFAULT_ZINDICES.l5 = DEFAULT_ZINDICES[5]
DEFAULT_ZINDICES.l6 = DEFAULT_ZINDICES[6]
DEFAULT_ZINDICES.l7 = DEFAULT_ZINDICES[7]
DEFAULT_ZINDICES.l8 = DEFAULT_ZINDICES[8]
DEFAULT_ZINDICES.l9 = DEFAULT_ZINDICES[9]

const getColorArray = (r, g, b) => {
  const alphas = ['.05', '.1', '.2', '.3', '.4', '.5', '.6', '.7', '.8', '.9']
  return alphas.map(a => `rgba(${r},${g},${b},${a})`)
}

// Default theme color
const DEFAULT_THEME_COLOR = {
  /**
   *
   * system style guides
   *
   * */
  primary: '#35b9e7',
  secondary: '#464646',
  dark: '#333333',
  light: '#eee',
  warning: '#e5c378',
  danger: '#ed553b',

  electricBlue: '#0d45fd',
  electricBlues: getColorArray(13, 69, 253),
  azure: '#0d98fd',
  azures: getColorArray(13, 152, 253),
  reddishOrange: '#fe5f16',
  reddishOranges: getColorArray(254, 95, 22),
  darkHotPink: '#e5006f',
  darkHotPinks: getColorArray(229, 0, 111),
  paleGrey: '#f2f6fe',
  veryLightGrey: '#c0c0c0',
  veryLightGreys: getColorArray(192, 192, 192),
  brownGrey: '#818181',
  brownGreys: getColorArray(129, 129, 129),
  starGreat: '#e02020',
  starAverage: '#f78800',
  starWeak: '#6d7278',
  black: '#282828', // = rgba(40, 40, 40, 1)
  blacks: getColorArray(40, 40, 40),
  white: '#fff',
  whites: getColorArray(255, 255, 255),
  textLight: 'rgba(40,40,40,0.4)',
  placeholderGrey: '#f2f2f2',
}

const DEFAULT_BORDER_RADIUS = [0, 2, 4, 8, 14, 9999, '100%']
const DEFAULT_FONT_WEIGHTS = [100, 200, 300, 400, 500, 600, 700, 800, 900]
DEFAULT_FONT_WEIGHTS.light = DEFAULT_FONT_WEIGHTS[1]
DEFAULT_FONT_WEIGHTS.normal = DEFAULT_FONT_WEIGHTS[3]
DEFAULT_FONT_WEIGHTS.bold = DEFAULT_FONT_WEIGHTS[6]
DEFAULT_FONT_WEIGHTS.bolder = DEFAULT_FONT_WEIGHTS[8]

const theme = {
  /** Style system specs. Key names are reserved and cannot be modified **/
  /** More specs: https://styled-system.com/table */
  breakpoints: DEFAULT_BREAK_POINTS,
  fontSizes: DEFAULT_FONT_SIZES,
  space: DEFAULT_SPACES,
  colors: DEFAULT_THEME_COLOR,
  radii: DEFAULT_BORDER_RADIUS,
  fontWeights: DEFAULT_FONT_WEIGHTS,
  zIndices: DEFAULT_ZINDICES,
  mediaQueries,
  /******* */

  common: {
    thumbnailWidth: '100px',
    controlPanelHeight: '50px',
    borderRadius: DEFAULT_BORDER_RADIUS[3],
  },
}

export default theme

