import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'ui-components'

const ErrorMessage = ({ message }) => (
  <Box p={3} bg="red" color="white">
    {message}
  </Box>
)

ErrorMessage.propTypes = {
  message: PropTypes.string,
}

export default ErrorMessage
