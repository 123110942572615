import React from 'react'
import PropTypes from 'prop-types'
import css from '@styled-system/css'

const Input = ({ label, ...rest }) => {
  const defaultInputStyles = {
    width: '100%',
    fontSize: 1,
    mb: 2,
    p: 1,
  }
  return (
    <label css={{ display: 'block' }}>
      <p
        css={css({ mt: 0, mb: 1, fontSize: 0, fontWeight: 'medium', color: 'text' })}
      >
        {label}
      </p>
      <input css={css(defaultInputStyles)} {...rest} />
    </label>
  )
}

Input.propTypes = {
  label: PropTypes.string,
}

export default Input
