import React from 'react'
import PropTypes from 'prop-types'
import css from '@styled-system/css'
import { auth } from '../../firebase'
import {
  googleProvider,
  githubProvider,
  twitterProvider,
  facebookProvider,
} from '../../firebase/auth'
import SocialLoginButton from './SocialLoginButton'
import GitHubIcon from '../icons/GitHub'
import GoogleIcon from '../icons/Google'
import TwitterIcon from '../icons/Twitter'
import FacebookIcon from '../icons/Facebook'

const SocialLogins = ({
  onSuccess = () => {},
  onError = () => {},
  ...restProps
}) => {
  const socialLogins = ['google', 'twitter', 'github', 'facebook']
  const enableGoogle = socialLogins.includes('google')
  const enableTwitter = socialLogins.includes('twitter')
  const enableGitHub = socialLogins.includes('github')
  const enableFacebook = socialLogins.includes('facebook')

  return (
    <div
      css={css({
        display: 'flex',
        flexDirection: 'column',
        'button:not(:last-child)': {
          mb: 1,
        },
      })}
      {...restProps}
    >
      {enableGoogle && (
        <SocialLoginButton
          onClick={async () => {
            try {
              const user = await auth.signInWithPopup(googleProvider())
              onSuccess(user)
            } catch (err) {
              console.error('Authentication Error: ', err)
              onError(err)
            }
          }}
        >
          <GoogleIcon css={css({ mr: 1 })} size={18} /> Sign in with Google
        </SocialLoginButton>
      )}
      {enableTwitter && (
        <SocialLoginButton
          onClick={async () => {
            try {
              const user = await auth.signInWithPopup(twitterProvider())
              onSuccess(user)
            } catch (err) {
              console.error('Authentication Error: ', err)
              onError(err)
            }
          }}
        >
          <TwitterIcon css={css({ mr: 1 })} size={18} /> Sign in with Twitter
        </SocialLoginButton>
      )}
      {enableFacebook && (
        <SocialLoginButton
          onClick={async () => {
            try {
              const user = await auth.signInWithPopup(facebookProvider())
              onSuccess(user)
            } catch (err) {
              console.error('Authentication Error: ', err)
              onError(err)
            }
          }}
        >
          <FacebookIcon css={css({ mr: 1 })} size={18} /> Sign in with Facebook
        </SocialLoginButton>
      )}
      {enableGitHub && (
        <SocialLoginButton
          onClick={async () => {
            try {
              const user = await auth.signInWithPopup(githubProvider())
              onSuccess(user)
            } catch (err) {
              console.error('Authentication Error: ', err)
              onError(err)
            }
          }}
        >
          <GitHubIcon css={css({ mr: 1 })} size={18} /> Sign in with Github
        </SocialLoginButton>
      )}
    </div>
  )
}

SocialLogins.propTypes = {
  onSuccess: PropTypes.object,
  onError: PropTypes.object,
}

export default SocialLogins
