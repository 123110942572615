import React from 'react'
import { withLayout } from 'components'

const NotFound = () => {
  return (
    <>
      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist...</p>
    </>
  )
}

export default withLayout(NotFound)
