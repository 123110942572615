import React from 'react'
import PropTypes from 'prop-types'
import css from '@styled-system/css'

const DisplayState = ({ profile }) => (
  <div
    css={css({
      my: 3,
      textAlign: 'left',
      fontFamily: 'monospace',
    })}
  >
    <h3
      css={css({
        p: 1,
        px: 2,
        my: 0,
        backgroundColor: '#000',
        color: 'white',
        fontSize: [3, 4, 5],
        textTransform: 'uppercase',
      })}
    >
      Profile State
    </h3>
    <pre
      css={css({
        my: 0,
        backgroundColor: 'paleGrey',
        fontSize: [2, 3, 4],
        padding: 2,
        overflowX: 'auto',
      })}
    >
      {JSON.stringify(profile, null, 2)}
    </pre>
  </div>
)

DisplayState.propTypes = {
  profile: PropTypes.object,
}

export default DisplayState
