import PropTypes from 'prop-types'
import {
  size,
  space,
  variant,
  border,
  color,
  typography,
  layout,
  flexbox,
  background,
  position,
} from 'styled-system'
import styled from 'styled-components'
import css from '@styled-system/css'

const btnStyle = ({ isOutlined }) =>
  variant({
    prop: 'btnStyle',
    variants: {
      primary: {
        color: isOutlined ? 'black' : 'electricBlue',
        bg: isOutlined ? 'white' : 'paleGrey',
        padding: '7px 12px',
        borderRadius: 0,
        border: '1px solid',
        borderColor: isOutlined ? 'blacks.0' : 'transparent',
      },
      secondary: {
        color: isOutlined ? 'black' : 'white',
        bg: isOutlined ? 'white' : 'black',
        padding: '7px 12px',
        borderRadius: 0,
        border: '1px solid',
        borderColor: isOutlined ? 'blacks.5' : 'transparent',
      },
      link: {
        color: 'inherit',
        bg: 'transparent',
        border: 'none',
      },
    },
  })

const disabledButtonStyle = css`
  pointer-events: none;
  cursor: inherit;
`

const Button = styled.button.attrs(({ type }) => ({
  type: type || 'button', // ['button', 'reset', 'submit']
}))`
  outline: none;
  cursor: pointer;
  /** variants */
  ${btnStyle}

  /** injected styled-system props */
  ${layout}
  ${space} /** margin & padding */
  ${size} /** width, height, size */
  ${border}
  ${color}
  ${typography}
  ${flexbox}
  ${background}
  ${position}
  ${({ disabled }) => disabled && disabledButtonStyle};
`

Button.propTypes = {
  btnStyle: PropTypes.oneOf(['primary', 'secondary', 'link']),
  isOutlined: PropTypes.bool,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  btnStyle: 'primary',
  isOutlined: false,
  disabled: false,
}

export default Button
