import React, { useState } from 'react'
import css from '@styled-system/css'
import { Link } from 'gatsby'
import { Button } from 'ui-components'
import { withLayout, DisplayState } from 'components'
import LoginModal from 'components/LoginModal'
import useAuth from 'hooks/useAuth'

const IndexPage = () => {
  const [toggleLogin, setToggleLogin] = useState(false)
  const { isLoading, profile } = useAuth()

  if (isLoading) {
    return null
  }

  return (
    <>
      <DisplayState profile={profile} />

      <div
        css={css({
          display: 'flex',
          flexWrap: 'wrap',
        })}
      >
        <Button
          mr={3}
          mb={3}
          btnStyle="primary"
          isOutlined={true}
          onClick={() => {
            setToggleLogin(true)
          }}
        >
          Login Modal
        </Button>

        <Link to="/login">
          <Button
            mr={3}
            mb={3}
            btnStyle="primary"
            isOutlined={true}
          >Login Page</Button>
        </Link>

        <Link to="/protected">
          <Button
            mr={3}
            mb={3}
            btnStyle="primary"
            isOutlined={true}
          >
            Protected Route
          </Button>
        </Link>
      </div>

      {toggleLogin && <LoginModal setToggleLogin={setToggleLogin} />}
    </>
  )
}

export default withLayout(IndexPage)
