import { createGlobalStyle } from 'styled-components'
import LatoRegularWoff from 'components/fonts/lato-v16-latin-regular.woff'
import LatoRegularWoff2 from 'components/fonts/lato-v16-latin-regular.woff2'
import LatoBoldWoff from 'components/fonts/lato-v16-latin-700.woff'
import LatoBoldWoff2 from 'components/fonts/lato-v16-latin-700.woff2'

const DefaultFontStyle = createGlobalStyle`
/* created with google-webfonts-helper: https://github.com/majodev/google-webfonts-helper */
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Lato Regular'), local('Lato-Regular'),
    url('${LatoRegularWoff2}') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('${LatoRegularWoff}') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Lato Bold'), local('Lato-Bold'),
    url('${LatoBoldWoff2}') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('${LatoBoldWoff}') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

body {
  color: #282828; /* theme color black  */
  min-width: 320px;
  font-size: 16px;
  font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Microsoft JhengHei', 微軟正黑體, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-scroll {
  overflow: hidden;
}

/***** Hide all default browser arrows in input number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
}
`

export default DefaultFontStyle
