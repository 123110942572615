import { css } from 'styled-components'

export const BaseLink = path => `https://icard.ai${path}`

export const Truncate = (width = '7em') => css`
  max-width: ${width};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ImageFluid = css`
  max-width: 100%;
  height: auto;
`

export const SmallText = (fontSize = 10) => css`
  font-size: ${fontSize / 16}rem;
  transform-origin: 0 0;
  transform: scale(${fontSize / 12});
`

export const HideScrollBar = css`
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
  /* for Firefox old versions  */
  overflow: -moz-scrollbars-none;
`

export const multiLineTruncate = (maxLines = 2) => css`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${maxLines};
`
