import React from 'react'
import css from '@styled-system/css'
import { useQueryParam, StringParam } from 'use-query-params';
import onLoginSuccess from '../firebase/auth/onLoginSuccess'
import onSignUpSuccess from '../firebase/auth/onSignUpSuccess'
import onResetSuccess from '../firebase/auth/onResetSuccess'
import { withLayout, FormState, Form } from 'components'
import { Box } from 'ui-components'

const FormWithHandlers = () => {
  const [ from ] = useQueryParam('from', StringParam);
  const loginRedirectPath = from || '/'
  const formState = FormState.useContainer()
  return (
    <Form
      onLoginSuccess={user => {
        onLoginSuccess({ ...formState, user, loginRedirectPath })
      }}
      safa
      onSignUpSuccess={user => {
        onSignUpSuccess({ ...formState, user, loginRedirectPath })
      }}
      onResetSuccess={() => {
        onResetSuccess({ ...formState, loginRedirectPath })
      }}
    />
  )
}

const LoginPage = () => {
  return (
    <Box
      css={css({
        display: 'flex',
        minHeight: '90vh',
        alignItems: 'center',
        justifyContent: 'center',
      })}
    >
      <FormState.Provider>
        <FormWithHandlers />
      </FormState.Provider>
    </Box>
  )
}

export default withLayout(LoginPage)
