import React from 'react'
import PropTypes from 'prop-types'
import css from '@styled-system/css'
import FormState from '../containers/FormState'
import { Button as ButtonBase } from 'ui-components'

const Button = ({ active, ...rest }) => (
  <ButtonBase
    css={css({
      width: '100%',
      bg: 'white',
      color: active ? 'primary' : 'border',
      borderTop: '1px solid',
      borderBottom: '1px solid',
    })}
    {...rest}
  ></ButtonBase>
)

Button.propTypes = {
  active: PropTypes.bool,
}

const Nav = ({ ...restProps }) => {
  const { formType, setFormType } = FormState.useContainer()

  return (
    <div
      css={css({ display: 'flex', width: '100%' })} {...restProps}
    >
      <Button
        active={formType === 'login'}
        css={css({
          borderRight: formType === 'login' ? '1px solid' : 0,
        })}
        onClick={() => {
          setFormType('login')
        }}
      >
        Log in
      </Button>
      <Button
        active={formType === 'signup'}
        css={css({
          borderLeft: formType === 'signup' ? '1px solid' : 0,
        })}
        onClick={() => {
          setFormType('signup')
        }}
      >
        Sign up
      </Button>
    </div>
  )
}

Nav.propTypes = {
}

export default Nav
