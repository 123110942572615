import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import css from '@styled-system/css'
import FormState from '../containers/FormState'
import { auth } from '../../firebase'
import Form from './FormBase'
import Input from './Input'
import { Button } from 'ui-components'
import SocialLogins from './SocialLogins'

const Separator = styled.hr`
  border: none;
  height: 1px;
  background-color: grey;
`

const SigninForm = ({ onSuccess = () => {}, ...restProps }) => {
  const { setFormType, setErrorMessage } = FormState.useContainer()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  return (
    <Form
      {...restProps}
      onSubmit={async event => {
        event.preventDefault()
        try {
          const user = await auth.signInWithEmailAndPassword(email, password)
          onSuccess(user)
        } catch (error) {
          setErrorMessage(error.message)
        }
      }}
    >
      <div>
        <SocialLogins onSuccess={onSuccess} />
        <Separator css={css({ my: 2 })} />
      </div>

      <Input
        label="Email"
        type="text"
        placeholder="email@example.com"
        value={email}
        onChange={event => {
          setEmail(event.target.value)
        }}
      />
      <Input
        label="Password"
        type="password"
        value={password}
        css={css({ mb: 3 })}
        onChange={event => {
          setPassword(event.target.value)
        }}
      />
      <Button type="submit" css={css({ width: '100%', mb: 2 })}>
        Login
      </Button>

      <Separator />

      <Button
        css={css({
          width: '100%',
          bg: 'white',
          color: 'primary',
          p: 0,
          fontSize: 0,
          fontWeight: 'body',
        })}
        onClick={event => {
          event.preventDefault()
          setFormType('passwordReset')
        }}
      >
        Forgot your password?
      </Button>
    </Form>
  )
}

SigninForm.propTypes = {
  onSuccess: PropTypes.func,
}

export default SigninForm
