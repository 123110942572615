import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text, ImageIcon } from 'ui-components'
import styled, { css } from 'styled-components'

const disabledStyle = css`
  pointer-events: none;
  opacity: 0.7;
`

const CheckboxContainer = styled(Box)`
  cursor: pointer;
  ${props => props.disabled && disabledStyle};
`

function Checkbox({ isChecked, value, onChange, children, ...restProps }) {
  const checked = isChecked || !!value
  return (
    <CheckboxContainer
      display="inline-flex"
      alignItems="center"
      onClick={() => onChange(!value)}
      {...restProps}
    >
      <Text mr={1} verticalAlign="middle">
        {!checked && <ImageIcon.Checkbox style={{ verticalAlign: 'middle' }} />}
        {checked && <ImageIcon.Check style={{ verticalAlign: 'middle' }} />}
      </Text>
      {children}
    </CheckboxContainer>
  )
}
Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func,
}

Checkbox.defaultProps = {
  onChange: () => {},
}

export default Checkbox
