import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { document } from 'browser-monads'
import styled from 'styled-components'
import css from '@styled-system/css'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { auth } from '../../firebase'
import useAuth from 'hooks/useAuth'
import { useToggle } from 'hooks/mixin'
import { Box, Flex, ImageIcon, Text } from 'ui-components'
import { BaseLink } from './mixin'
import BurgerMenu from './BurgerMenu'
import NavLinks from './NavLinks'

const Header = ({ showOptions }) => {
  const { on: isBurgerOpen, toggle: toggleBurgerOpen } = useToggle()
  const closeBurger = useCallback(() => {
    if (isBurgerOpen) toggleBurgerOpen()
  }, [isBurgerOpen, toggleBurgerOpen])

  const { isLoggedIn } = useAuth()

  const ref = useRef()

  useScrollPosition(
    ({ currPos }) => {
      if (!ref.current) return
      if (currPos.y > 0) return ref.current.classList.add('sticky')
      ref.current.classList.remove('sticky')
    },
    [],
    null,
    true,
    200,
  )

  useEffect(() => {
    if (isBurgerOpen) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [isBurgerOpen])

  const Caption = () => {
    return <ImageIcon.Logo />
  }

  return (
    <Box
      pb="78px"
      display={{
        _: showOptions.mobile ? 'flex' : 'none',
        desktop: showOptions.desktop ? 'flex' : 'none',
      }}
    >
      <MenuCheckbox
        type="checkbox"
        className={isBurgerOpen && 'isBurgerOpen'}
      />
      <FixedBox ref={ref}>
        <Line />
        <Content>
          <BurgerMenu onClick={toggleBurgerOpen}>
            <div className="menu">
              <MenuLine className="top"></MenuLine>
              <MenuLine className="middle"></MenuLine>
              <MenuLine className="bottom"></MenuLine>
            </div>
          </BurgerMenu>
          <HomePageLink href={BaseLink('/')}>
            <Text margin={0} fontSize="17px" fontWeight="normal">
              {Caption()}
            </Text>
          </HomePageLink>
          <NavLinks
            bg={{ _: 'white', desktop: 'unset' }}
            onClick={closeBurger}
          />
          <Flex alignItems="center" justifySelf="end">
            <Text
              as="a"
              mr={2}
              p={1}
              className="header-profile"
              onClick={() => auth.signOut()}
            >
              {isLoggedIn ? (
                <ImageIcon.LoggedinAccount
                  title="loggedin account"
                  width="22px"
                  height="22px"
                />
              ) : (
                <ImageIcon.Account title="account" />
              )}
            </Text>
          </Flex>
        </Content>
        {/* if promotion banner is removed, header padding & Carousel margin need to be updated  */}
        <PromotionBanner>
          即時更新7,000商家，超過20,000筆全台完整信用卡優惠
        </PromotionBanner>
      </FixedBox>
    </Box>
  )
}

Header.propTypes = {
  showOptions: PropTypes.shape({
    desktop: PropTypes.bool,
    mobile: PropTypes.bool,
  }),
}

Header.defaultProps = {
  showOptions: {
    desktop: true,
    mobile: true,
  },
}

const Line = styled(Box)`
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.electricBlue},
    ${({ theme }) => theme.colors.azure}
  );
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

const FixedBox = styled(Box)`
  height: 50px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.25s ease;

  &.sticky > ${Line} {
    top: 48px;
  }
`
FixedBox.defaultProps = {
  as: 'header',
  color: 'black',
  bg: 'white',
  zIndex: 'header',
}

const HomePageLink = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    left: 0;
    transform: translateX(0);
  }
`
HomePageLink.defaultProps = {
  as: 'a',
  color: 'black',
  zIndex: 'topmost',
}

const MenuCheckbox = styled.input`
  display: none;
  &.isBurgerOpen {
    & ~ header {
      background-color: white;
      height: 100%;
      max-height: none;
      z-index: ${({ theme }) => theme.zIndices.topmost};
      ${Line} {
        display: none;
      }

      nav {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
      }
      .header-profile {
        display: none;
      }
      label {
        .top {
          transform: translateY(7px) rotate(45deg);
        }
        .middle {
          opacity: 0;
        }
        .bottom {
          transform: translateY(-5px) rotate(-45deg);
        }
      }
    }
  }
`

const Content = styled(Box)`
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 4px;
`
Content.defaultProps = {
  mx: 4,
}

const PromotionBanner = styled('div')(
  css({
    position: 'absolute',
    top: '50px',
    left: 0,
    right: 0,
    height: '28px',
    color: 'white',
    opacity: 0.9,
    fontSize: ['body', 'intro', 'h4'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: ({ colors }) =>
      `linear-gradient(to right, ${colors.electricBlue} 0%, ${colors.azure} 100%)`,
  }),
)

const MenuLine = styled(Text)`
  display: block;
  height: 1px;
  margin-bottom: 5px;
  transition: transform 250ms, opacity 250ms;
  width: 22px;
  &.top,
  &.bottom {
    transform: none;
  }
  &.bottom {
    margin-bottom: 0;
  }
  &.middle {
    opacity: 1;
  }
`
MenuLine.defaultProps = {
  bg: 'black',
}

// const MemoHeader = memo(Header)

export default Header
