import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box } from 'ui-components'

function BurgerMenu({ children, ...props }) {
  return <StyledBurgerMenu {...props}>{children}</StyledBurgerMenu>
}

BurgerMenu.propTypes = {
  children: PropTypes.node,
}

const StyledBurgerMenu = styled(Box)`
  cursor: pointer;

  .menu {
    color: ${({ theme }) => theme.colors.black};
    display: inline-block;
    user-select: none;
  }
`
BurgerMenu.defaultProps = {
  as: 'label',
  btnStyle: 'link',
  alignItems: 'center',
  borderStyle: 'none',
  justifySelf: 'start',
  display: { _: 'flex', desktop: 'none' },
}

export default BurgerMenu
