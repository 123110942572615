import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, typography, color, layout } from 'styled-system'
import { Box, Flex } from 'ui-components'
import { BaseLink, SmallText } from './mixin'
import fbIcon from 'components/images/f_logo_RGB-White_72.png'

const MenuEntry = styled.a`
  ${color}
  ${typography}
  ${space}
  ${layout}
`

MenuEntry.defaultProps = {
  color: 'white',
  display: 'block',
  fontSize: 'h4',
  py: 3,
  px: { desktop: '20px' },
}

const FooterLinks = () => {
  return (
    <>
      <MenuEntry href={BaseLink('/home/recmd_cards')}>找信用卡</MenuEntry>
      <MenuEntry href={BaseLink('/diagnosis')} textAlign="center">
        刷哪張好
      </MenuEntry>
      <MenuEntry href={BaseLink('/home/select_card')} textAlign="right">
        比較卡片
      </MenuEntry>
      <MenuEntry href={BaseLink('/home/find_card')}>進階找卡</MenuEntry>
      <MenuEntry href={BaseLink('/home/recmd_loans')} textAlign="center">
        精選信貸
      </MenuEntry>
    </>
  )
}

const Footer = ({ showOptions }) => {
  const [fanpageURL, setFanpageURL] = useState(
    'https://www.facebook.com/iCardAI-107409260893307',
  )
  useEffect(() => {
    const checkDeviceType = async () => {
      const { isIOS, isAndroid } = await import('react-device-detect')
      if (isIOS) {
        setFanpageURL('fb://profile/107409260893307')
      }

      // https://stackoverflow.com/questions/4810803/open-facebook-page-from-android-app
      if (isAndroid) {
        setFanpageURL(
          'fb://facewebmodal/f?href=https://www.facebook.com/iCardAI-107409260893307/',
        )
      }
    }

    checkDeviceType()
  }, [setFanpageURL])

  const year = new Date().getFullYear()
  return (
    <Box
      as="footer"
      px={6}
      py={4}
      bg="black"
      color="white"
      display={{
        _: showOptions.mobile ? 'block' : 'none',
        desktop: showOptions.desktop ? 'block' : 'none',
      }}
    >
      <Flex alignItems="center" justifyContent="space-between" mb={2}>
        <Box as="h3" fontSize="h3" fontWeight="normal" m={0}>
          iCard.AI
        </Box>
        <Flex
          display={{ _: 'none', desktop: 'flex' }}
          flex={1}
          justifyContent="center"
        >
          <FooterLinks />
        </Flex>
        <a
          title="iCard.AI 粉絲專頁"
          href={fanpageURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={fbIcon} alt="facebook fanpage" width="16px" />
        </a>
      </Flex>
      <Box
        as="nav"
        display={{ _: 'grid', desktop: 'none' }}
        gridTemplateColumns="repeat(3, 1fr)"
      >
        <FooterLinks />
      </Box>
      <Copyright>
        <p>
          &copy; {year} iCard.AI 版權所有。使用此網站即視為同意我們的
          <a href={BaseLink('/terms')} target="_blank" rel="noreferrer">
            服務條款
          </a>
          及
          <a href={BaseLink('/privacy')} target="_blank" rel="noreferrer">
            隱私權政策
          </a>
          。
        </p>
      </Copyright>
    </Box>
  )
}

const Copyright = styled(Box)`
  text-align: center;
  display: flex;
  justify-content: center;
  p {
    ${SmallText(10)}
    line-height: normal;
    transform-origin: unset;
  }
  a {
    color: white;
    font-weight: bold;
    text-decoration: underline;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    p {
      ${SmallText(12)};
      transform-origin: unset;
    }
  }
`
Copyright.defaultProps = {
  mt: 4,
  mb: 0,
  color: 'white',
}

Footer.propTypes = {
  showOptions: PropTypes.shape({
    desktop: PropTypes.bool,
    mobile: PropTypes.bool,
  }),
}

Footer.defaultProps = {
  showOptions: {
    desktop: true,
    mobile: true,
  },
}
export default Footer
