import { useState, useEffect } from 'react'
import { auth } from '../firebase'

const useAuth = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [profile, setProfile] = useState(null)

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setProfile(user)
      } else {
        setProfile(null)
      }

      setIsLoading(false)
    })

    // Unsubscribe to the listener when unmounting
    return () => {
      unsubscribe()
    }
  }, [])

  return {
    isLoading,
    isLoggedIn: !!profile,
    profile,
  }
}

export default useAuth
