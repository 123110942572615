import styled from 'styled-components'
import { Box } from './design-system'

const Container = styled(Box)`
  max-width: 700px;
  margin: 0 auto;
`

export default Container

const InPageContainer = styled(Box)``
InPageContainer.defaultProps = {
  px: { _: 4, desktop: '30px' },
  py: { _: 0, desktop: '30px' },
  mx: { _: 0, desktop: 'auto' },
  mt: 0,
  mb: { _: 0, desktop: 7 },
  width: { desktop: '700px' },
  boxShadow: { desktop: '0 0 4px 0 rgba(0, 0, 0, 0.2)' },
  bg: 'white',
  overflow: { desktop: 'hidden' },
}

export { InPageContainer }
