import React from 'react'
import { ReactComponent as Logo } from 'components/images/logo.svg'
import { ReactComponent as Checkbox } from 'ui-components/Icon/svg/checkbox.svg'
import { ReactComponent as Account } from 'ui-components/Icon/svg/account.svg'
import { ReactComponent as LoggedinAccount} from 'ui-components/Icon/svg/account-login.svg'

/*
 SVG can be used as React component or static file with the power of SVGR
 https://www.gatsbyjs.com/plugins/gatsby-plugin-svgr/
*/

const ImageIcon = props => <i {...props} />

// SVG icon alias
ImageIcon.Logo = Logo
ImageIcon.Checkbox = Checkbox
ImageIcon.Account = Account
ImageIcon.LoggedinAccount = LoggedinAccount

export default ImageIcon
