import React from 'react'
import { ThemeProvider } from 'styled-components'
import StyleReset from 'components/StyleReset'
import DefaultFontStyle from 'components/DefaultFontStyle'
import { Flex } from 'ui-components'
import theme from 'components/theme'
import Header from './Header'
import Footer from './Footer'

export default function withLayout(
  Component,
  /** ------ initial withLayout options -------
   * Header and footer are hide by default.
   * You can have further mobile and desktop display customizations like so:
   * withHeader: {
   *   desktop: false,
   *   mobile: true
   * }
   * withFooter: {
   *   desktop: true,
   *   mobile: false
   * }
   * ---------------------------------------
   */
  options = {
    withHeader: {
      desktop: true,
      mobile: true,
    },
    withFooter: {
      desktop: true,
      mobile: true,
    },
  },
) {
  const Layout = props => {
    return (
      <>
        <StyleReset />
        <DefaultFontStyle />
        <ThemeProvider theme={theme}>
          <Flex flexDirection="column" minHeight="100vh">
            <Header showOptions={options.withHeader} />
            <Flex as="main" flexGrow="1" flexDirection="column">
              <Component {...props} />
            </Flex>
            <Footer showOptions={options.withFooter} />
          </Flex>
        </ThemeProvider>
      </>
    )
  }

  return Layout
}
