import React from 'react'
import css from '@styled-system/css'
import { Button } from 'ui-components'

const SocialLoginButton = ({ ...rest }) => {
  return (
    <Button
      css={css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: 'dark',
        background: 'white',
        fontWeight: 'bold',
        border: '1px solid',
        p: '0.8rem',
      })}
      {...rest}
    />
  )
}

export default SocialLoginButton
