import React from 'react'
import styled from 'styled-components'
import { Text, Box } from 'ui-components'

const MenuEntry = styled(Text)`
  cursor: pointer;
`

MenuEntry.defaultProps = {
  as: 'a',
  display: 'block',
  fontSize: 'h4',
  px: 4,
  py: 4,
  borderBottomWidth: '1px',
  borderBottomColor: 'paleGrey',
  borderBottomStyle: { _: 'solid', desktop: 'none' },
  color: 'black',
}

const Nav = styled(Box)`
  margin: 0 ${({ theme }) => -theme.space[4]}px;
  opacity: 0;
  transform: translateY(-15px);
  transition: all 0.25s ease;
  visibility: hidden;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    margin: initial;
    opacity: 1;
    transform: translateY(0);
    transition: none;
    visibility: visible;
  }
`
Nav.defaultProps = {
  as: 'nav',
  display: { _: 'block', desktop: 'flex' },
  alignItems: 'center',
  flex: '1',
  justifyContent: 'flex-end',
  order: { _: 4, desktop: 0 },
  position: { _: 'absolute', desktop: 'static' },
  top: '48px',
  left: '0',
  right: '0',
  zIndex: 'l1',
}

export const navItems = {
  profile: {
    name: '會員專區',
    path: '/',
  },
}

function NavLinks(props) {
  return (
    <Nav {...props}>
      <MenuEntry
        href={navItems.profile.path}
      >
        {navItems.profile.name}
      </MenuEntry>
    </Nav>
  )
}
export default NavLinks
