import React from 'react'
import css from '@styled-system/css'

const Header = ({ ...rest }) => {
  return (
    <div
      css={css({
        m: 0,
        p: 3,
        bg: 'white',
        color: 'primary',
        border: 0,
      })}
      {...rest}
    />
  )
}

export default Header
